<template>
  <section class="mx-2 mx-md-8">
    <span
      class="text-h6 text-md-h5 secondary--text"
      v-text="'Agregar Insumo'"
    />
    <v-row class="mt-2">
      <v-col cols="12" md="6">
        <v-autocomplete
          :error-messages="cifradosErrors"
          :items="cifrados_presupuestarios"
          @blur="$v.form.cifrados.$touch()"
          clearable
          item-text="cifrado_presupuestario"
          item-value="id"
          label="Cifrado presupuestario *"
          outlined
          return-object
          v-model="form.cifrados"
        />
      </v-col>
    </v-row>
    <DetalleCifradoComponent :cifrado="form.cifrados" />

    <v-row>
      <v-col cols="12" lg="2" md="3" class="pt-0">
        <v-autocomplete
          :disabled="form.cifrados == null"
          :error-messages="tipoOBSErrors"
          :items="tiposOBS"
          @blur="$v.form.tipoOBS.$touch()"
          clearable
          item-text="nombre"
          item-value="id"
          label="Tipo OBS *"
          outlined
          v-model="form.tipoOBS"
        />
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <template>
          <v-autocomplete
            :disabled="form.tipoOBS == null"
            :error-messages="objetoErrors"
            :filter="filterObsSelect"
            :items="listado_obs"
            :loading="isLoading"
            :maxLength="200"
            @blur="$v.form.objeto.$touch()"
            @keyup="isLoading = true"
            @update:search-input="searchObsSelect"
            clearable
            hide-no-data
            item-text="codigoNombre"
            item-value="id"
            label="Digitar OBS *"
            outlined
            placeholder="Escriba el nombre o código del OBS y luego selecciónelo *"
            return-object
            v-model="form.objeto"
          />
        </template>
      </v-col>
    </v-row>
    <DetalleOBSComponent :obs="form.objeto" />
    <v-row>
      <v-col
        cols="12"
        class="mt-4 mt-sm-8 d-flex justify-center flex-column justify-sm-start flex-sm-row"
      >
        <v-btn
          @click="redirectPage"
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          outlined
          v-text="'Cancelar'"
        />
        <v-btn
          @click="next()"
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          v-text="'Continuar'"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapMutations, mapState } from "vuex";
import DetalleOBSComponent from "./components/DetalleOBSComponent.vue";
import DetalleCifradoComponent from "./components/DetalleCifradoComponent.vue";

export default {
  name: "AgregarInsumoView",

  components: { DetalleOBSComponent, DetalleCifradoComponent },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },

  validations: {
    form: {
      cifrados: {
        required,
      },
      tipoOBS: {
        required,
      },
      objeto: {
        required,
      },
    },
  },

  data: () => ({
    form: {
      cifrados: null,
      tipoOBS: null,
      objeto: null,
    },
    tiposOBS: [],
    cifrados_presupuestarios: [],
    listado_obs: [],
    isLoading: false,
    obs_timer: null,
  }),

  computed: {
    ...mapState("agregarInsumo", ["id_solicitud"]),
    ...mapState(["selectedUnidad"]),
    cifradosErrors() {
      const errors = [];
      if (!this.$v.form.cifrados.$dirty) return errors;
      !this.$v.form.cifrados.required &&
        errors.push("Cifrado presupuestario es obligatorio");
      return errors;
    },
    tipoOBSErrors() {
      const errors = [];
      if (!this.$v.form.tipoOBS.$dirty) return errors;
      !this.$v.form.tipoOBS.required &&
        errors.push("Tipo de OBS es obligatorio");
      return errors;
    },
    objetoErrors() {
      const errors = [];
      if (!this.$v.form.objeto.$dirty) return errors;
      !this.$v.form.objeto.required && errors.push("OBS  es obligatorio");
      return errors;
    },
  },

  methods: {
    ...mapMutations("agregarInsumo", [
      "setObjetoPresupuestario",
      "setNuevoInsumoStep1",
    ]),
    filterObsSelect(item, queryText, itemText) {
      // crear array de palabras a buscar
      const words = queryText.split(" ");
      // crear array de palabras del item
      const hasWords = itemText.split(" ");

      // retornar valores que coincidan con algunas de las palabras a buscar
      return words.some((word) => {
        return hasWords.some((hasWord) => {
          return hasWord.toLowerCase().indexOf(word.toLowerCase()) > -1;
        });
      });

      // // recorrer array de palabras a buscar
      // return words.every((word) => {
      //   // retornar true si alguna palabra del item contiene la palabra a buscar
      //   return hasWords.some((hasWord) => {
      //     return hasWord.toLowerCase().indexOf(word.toLowerCase()) > -1;
      //   });
      // });
    },
    redirectPage() {
      if (this.$route.params.tipo != null) {
        this.$router.replace({
          name: "compras",
          params: {
            tipo: Number(this.$route.params.tipo),
            id_solicitud: this.id_solicitud,
          },
        });
      } else {
        this.$router.replace({ name: "crear-solicitud-compra" });
      }
    },
    async getTipoObs() {
      const { data } = await this.services.Obs.getObsCategoriaList();
      if (data) {
        this.tiposOBS = data;
        if (this.$route.params.tipo == 3) {
          this.tiposOBS = this.tiposOBS.filter((item) => item.id !== 2);
        }
      }
    },
    async getCifradosPresupuestarios() {
      const { data, status } = await this.services.Cifrados.getCifrados({
        id_unidad: this.selectedUnidad.id,
        fondos: true,
      });
      if (status == 200) {
        this.cifrados_presupuestarios = data;
      }
    },
    guardarNuevoInsumo() {
      let params = {
        id_financiamiento_unidad: this.form.cifrados.id,
        id_obs: this.form.objeto.id,
        monto_disponible: this.form.cifrados.monto_disponible,
      };
      this.setObjetoPresupuestario(this.form.objeto);
      this.setNuevoInsumoStep1(params);
    },
    async searchObsSelect(value) {
      if (this.obs_timer) {
        clearTimeout(this.obs_timer);
      }
      if (this.searchParam > 0) {
        return;
      }

      if (this.form.tipoOBS != null) {
        this.obs_timer = setTimeout(async () => {
          let params = {
            search: value,
            // id_tipo_obs: this.form.tipoOBS,
            id_tipo_obs: 4,
            id_obs_clasificacion: this.form.tipoOBS,
            // cuestion del tec jorge
          };

          let codigoObs = Number(value);

          if (
            (codigoObs && codigoObs != NaN && value?.length >= 2) ||
            (value?.length > 2 && isNaN(codigoObs))
          ) {
            const { data } =
              await this.services.LineasTrabajos.getObjetoPresupuestario(
                params
              );
            const elements = [];
            data.forEach((obs) => {
              elements.push({
                ...obs,
                codigoNombre: `${obs.codigo} - ${obs.nombre}`,
              });
            });
            this.listado_obs = elements;
          }
          this.isLoading = false;
        }, 500);
      }
    },
    next() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        this.guardarNuevoInsumo();
        this.$router.push({
          name: "agregar-insumo-detalles",
          params: {
            idSolicitud: this.$route.params.id_solicitud,
            tipo: Number(this.$route.params.tipo),
          },
        });
      } else {
        this.temporalAlert({
          show: true,
          message: "Hay campos obligatorios vacíos",
          type: "error",
        });
      }
    },
  },
  async created() {
    this.getCifradosPresupuestarios();
    this.getTipoObs();
  },
};
</script>
