<template>
  <div v-if="cifrado" class="pt-0 pb-10">
    <v-row class="align-center">
      <v-col md="6">
        <label class="text-h6 ma-0">
          {{ cifrado?.cifrado_presupuestario }}
        </label>
        <p
        v-for="catalogos in cifrado.fondo?.catalogos_cifrados"
        :key="catalogos?.id"
        class="text-h7 ma-0"
        >
        {{ catalogos?.codigo }} {{ catalogos?.nombre }}
      </p>
    </v-col>
    <v-col class="">
      <v-row class="justify-center">
        <v-chip class="white--text text-h6" color="primary" >
          Monto Disponible: $
          {{ Intl.NumberFormat("en-US", {
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(cifrado?.monto_disponible) }}
        </v-chip>
      </v-row>
    </v-col>
  </v-row>
  </div>
</template>
<script>
export default {
  name: "DetalleCifradoComponent",
  components: {},
  props: {
    cifrado: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
