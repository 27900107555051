<template>
  <v-stepper v-model="e1">
    <v-stepper-header v-show="false">
      <v-stepper-step :complete="e1 > 1" step="1"> </v-stepper-step>
      <v-stepper-step :complete="e1 > 2" step="2"> </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <!-- <AgregarInsumoStep1Component @next="next"></AgregarInsumoStep1Component> -->
      </v-stepper-content>

      <v-stepper-content step="2">
        <AgregarInsumoStep2Component
          :obs="obs"
          @back="back()"
        ></AgregarInsumoStep2Component>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
//import AgregarInsumoStep1Component from "./components/AgregarInsumoStep1Component.vue";
import AgregarInsumoStep2Component from "./components/AgregarInsumoStep2Component.vue";
export default {
  name: "agregarInsumoCompraView",
  components: {
    AgregarInsumoStep1Component,
    AgregarInsumoStep2Component,
  },
  props: {},
  data: () => ({
    e1: 1,
    obs: null,
  }),
  computed: {},
  watch: {},
  methods: {
    next(obs) {
      this.e1 = 2;
      this.obs = obs;
    },
    back() {
      this.e1 = 1;
    },
  },
  mounted() {},
  created() {},
};
</script>
