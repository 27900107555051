<template>
  <div v-if="obs" class="pt-0 pb-10">
    <p class="text-h6 ma-0">
      {{ comptCodigoNombre }}
    </p>
    <p class="text-h7 ma-0" v-if="checkObsClase">
      Clase : {{ obs.codigo_obs_clase + " " + obs.nombre_obs_clase }}
    </p>
    <p class="text-h7 ma-0" v-if="checkObsFamilia">
      Familia : {{ obs.codigo_obs_familia + " " + obs.nombre_obs_familia }}
    </p>
    <p class="text-h7 ma-0" v-if="checkObsSegmento">
      Segmento: {{ obs.codigo_obs_segmento + " " + obs.nombre_obs_segmento }}
    </p>
  </div>
</template>
<script>
export default {
  name: "DetalleOBSComponent",
  components: {},
  props: {
    obs: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    comptCodigoNombre() {
      if (this.obs.codigo && this.obs.nombre) {
        return this.obs.codigo + " " + this.obs.nombre;
      } else {
        return "";
      }
    },
    checkObsClase() {
      return this.obs.codigo_obs_clase && this.obs.nombre_obs_clase;
    },
    checkObsFamilia() {
      return this.obs.codigo_obs_familia && this.obs.nombre_obs_familia;
    },
    checkObsSegmento() {
      return this.obs.codigo_obs_segmento && this.obs.nombre_obs_segmento;
    },
  },
};
</script>
